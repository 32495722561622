<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 代理等级 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey"  @keyup.enter.native="goodsSearch" placeholder="品牌名称" style="width: 200px;margin-right: 10px;"></el-input>
			</div>
			<div class="filter-item">

				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
				<buttonPermissions :datas="'addBrand'">
					<el-button type="primary" style="margin-left:15px;width:90px;" @click="eidtGroup({})">添加</el-button>
				</buttonPermissions>

			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" v-loading="loading">
				<el-table-column prop="BrandName" label="品牌名称"></el-table-column>
				<el-table-column label="品牌商品">
					<template slot-scope="scope">
						<div v-if="scope.row.ProductCount==0">{{scope.row.ProductCount}}</div>
						<div v-else style="color: #409EFF;cursor:pointer" @click="jumpToShop(scope.row)">{{scope.row.ProductCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<buttonPermissions :datas="'reviseBrand'">
							<el-button type="text" @click="eidtGroup(scope.row)">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'deleteBrand'">
							<span style="color:#f00;margin-left:10px;cursor: pointer;" @click="deleteChose(scope.row)">删除</span>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 品牌名称编辑的弹框 -->
		<el-dialog :visible.sync="editVisable" width="50%" title="添加品牌" :show-close="false">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="品牌名称:" prop="name">
						<el-input v-model="ruleForm.name" maxlength="16" clearable style="width:300px" placeholder="最多输入16个字"></el-input>
					</el-form-item>
				</el-form>
				<!-- <label class="label">品牌名称: </label>
				<el-input v-model="groupName" placeholder="最多输入16个汉字" maxlength="16" clearable style="flex:1">
				</el-input> -->
			</div>

			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="editVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange('ruleForm')" :loading="loading">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		productBrandListAll,
		productBrandListAllsave,
		productBrandListAlldelete
	} from '@/api/goods'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入品牌名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入品牌名称'));
				} else {
					return callback()
				}
			};
			return {
				ruleForm: {
					name: ''
				},
				rules: {
					name: [{
							required: true,
							// message: '请输入品牌名称',
							trigger: 'blur',
							validator: checkName
						},
						{
							min: 1,
							max: 16,
							message: '最多输入16个字',
							trigger: 'blur'
						}
					]
				},
				groupData: [{
					addTime: '2016-05-02',
					goodsName: 'Swarovski/施华洛世奇粉钻天鹅项链',
					stock: 10,
				}],
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: "",
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				eidtGroupData: {}
			};
		},
		beforeMount() {
			//获取列表数据}
			this.getList()
		},
		methods: {
			jumpToShop(val) {
				this.$router.push({
					path: '/goods/goodsList',
					query: {
						ProductBrandId: val.Id
					}
				})
			},
			// 获取列表数据
			async getList() {
				try {
					let data = {
						KeyWord: this.searchKey, // 搜索关键字
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据

					}
					let result = await productBrandListAll(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			goodsAdd() {
				this.editVisable = true
			},
			goodsSearch() {
				this.currentPage = 1;
				this.getList();
			},
			eidtGroup(item) {
				this.eidtGroupData = item;
				this.ruleForm.name = item.BrandName ? item.BrandName : ''
				this.editVisable = true
			},
			deleteChose(record) {
				this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {

					let result = await productBrandListAlldelete({
						Id: record.Id
					})
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})


			},
			saveChange(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true;
							let data = {
								Id: this.eidtGroupData.Id ? this.eidtGroupData.Id : 0,
								BrandName: this.ruleForm.name
							}
							let result = await productBrandListAllsave(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.editVisable = false;
							}

						} catch (e) {
							console.log(e)
						} finally {
							this.goodsSearch();
							setTimeout(() => {
								this.loading = false
							}, 500)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style>
</style>
